import { Component, OnInit } from '@angular/core';
import { HealthCheckService } from './health-check.service';

@Component({
  selector: 'app-health-check',
  templateUrl: './health-check.component.html',
  styleUrls: ['./health-check.component.css']
})
export class HealthCheckComponent implements OnInit {

  healthStatuses: any[] = [];
  constructor(private healthcheckService : HealthCheckService ) { }

  ngOnInit(): void {
    this.getHealthStatuses();
  }

  getHealthStatuses(): void {
    this.healthcheckService.checkHealth().subscribe(
      (status) => {
        this.healthStatuses = status;
      },
      (error) => {
        console.error('Error fetching health status:', error);
      }
    );
  }

  getStatusClass(status: string): string {
    return status === 'healthy' ? 'status-up' : 'status-down';
  }

}
