import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { WelcomeComponent } from './welcome/welcome.component';
import { VehicleModule } from './vehicle/vehicle.module';
import { ConsoleLogger, Logger } from './log/logger';
import { UserProfileFilterPipe } from './shared/pipes/user-profile-filter.pipe';
import { SearchControlsComponent } from './search-controls/search-controls.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { StoreModule } from '@ngrx/store';
import * as fromRoot from './state/app.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { EffectsModule } from '@ngrx/effects';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './services/auth-interceptor.service';
import { VehicleEffects } from './vehicle/state/vehicle.effects';
import { CustomSelectComponent } from './shared/custom-select/custom-select.component';
import { BlankComponent } from './blank.component';
import { PriceNewComponent } from './shared/price-new/price-new.component';
import { AdminComponent } from './admin/admin.component';
import { AdminModule} from './admin/admin.module';
import { LoaderComponent } from './shared/loader/loader.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { HealthCheckComponent } from './health-check/health-check.component';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    WelcomeComponent,
    UserProfileFilterPipe,
    CustomSelectComponent,
    SearchControlsComponent,
    PriceNewComponent,  
    BlankComponent,
    LoaderComponent,
    UnauthorizedComponent,
    HealthCheckComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    VehicleModule,
    AdminModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    OAuthModule.forRoot(),
    StoreModule.forRoot(fromRoot.reducer),
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      name: 'ASL App DevTools',
      maxAge: 25,
      logOnly: environment.production
    })    
  ],
  providers: [
    CookieService,
    { provide: Logger, useClass: ConsoleLogger },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService , multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
