import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConfigService } from '../services/config.service';

@Injectable({
  providedIn: 'root'
})
export class HealthCheckService {

  // Define the health check URLs for your microservices
  private healthUrls = [];
  errorMessage: any;
  appConfig: any;

  constructor(private http: HttpClient,private configService: ConfigService,) {
    this.configService.getConfigStatus().subscribe(
      _configStatus => {
        if (_configStatus) {
          this.appConfig = this.configService.getGlobalConfig();
          this.healthUrls.push(this.appConfig.aslApiHealth);
          //this.healthUrls.push("https://gatewayt.verisk.com/rating/autosymbollookup/report/v1/healthcheck");
        }
      },
      error => this.errorMessage = <any>error
    );
  }

  // Method to check the health of all microservices
  checkHealth(): Observable<any> {
    const healthChecks = this.healthUrls.map(url =>
      this.http.get(url).pipe(
        map(() => ({ url, status: 'healthy' })), // Map success to 'healthy' status
        catchError(error => of({ url, status: 'DOWN' })) // Catch errors and return 'DOWN' status
      )
    );
  
    // Combine all the observables into one and return
    return forkJoin(healthChecks);
  }
}
